import React, { FC, useEffect, useMemo, useState } from "react";
import { getProductsCatalogList } from "@/actions/products.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { debounce } from "lodash";
import { CatalogList } from "@/components/pages/platform/pages/Catalog/CatalogPage/CatalogList/CatalogList";
import EmptyResult from "@/components/ui-kit/emptyResult";
import Preloader from "@/components/ui-kit/preloader";
import SearchInput from "@/components/ui-kit/searchInput";

interface ISupplierCardCatalogProps {
  supplierId: number;
}

const SupplierCardCatalog: FC<ISupplierCardCatalogProps> = ({ supplierId }): JSX.Element => {
  const dispatch = useDispatch();

  const { isFetching, productsCatalogList } = useSelector((state: AppStateType) => state.products);

  const [searchValue, setSearchValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchProductCatalogList(currentPage, searchValue?.trim());
  }, [currentPage]);

  const fetchProductCatalogList = (page: number, search: string | null): void => {
    dispatch(
      getProductsCatalogList({
        page,
        pageSize: 15,
        supplierId: supplierId?.toString() || null,
        search: search || null,
      })
    );
  };

  const onSearch = (value: string): void => {
    fetchProductCatalogList(1, value?.trim());
  };

  const debouncedSearchValue = useMemo(() => {
    return debounce((value: string) => {
      setSearchValue(value);
      onSearch(value);
    }, 500);
  }, []);

  return (
    <div className="mt-12">
      <div className="flex justify-between items-center flex-wrap mb-3">
        <h1 className="text-x1 mb-5">Каталог</h1>
        <SearchInput
          changeValue={debouncedSearchValue}
          placeholder="Найти в каталоге поставщика"
          maxLength={200}
        />
      </div>
      {!isFetching ? (
        <div>
          {!productsCatalogList?.length && (
            <div className="mb-5">
              <EmptyResult text="Здесь будет ваш каталог" />
            </div>
          )}
          <CatalogList currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      ) : (
        <div className="flex justify-center">
          <Preloader />
        </div>
      )}
    </div>
  );
};

export default SupplierCardCatalog;
