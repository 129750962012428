import React, { FC, useEffect, useState } from "react";

import * as OrdersAPI from "@/api/orders.api";
import { OrderCommentType, OrderType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { fetchCommentList } from "@/actions/orders.actions";
import { roleResolver } from "@/utils/roleResolver";

import SquareWithPlusIcon from "@/assets/icons/square-with-plus.inline.svg";

import Textarea from "@/components/ui-kit/textarea";
import Button from "@/components/ui-kit/button";
import Comment from "@/components/orderDiscussion/Comment/Comment";

import css from "./orderDiscussion.modules.scss";

interface IOrderDiscussionProps {
  order: OrderType;
  creatorsName: string;
}

const OrderDiscussion: FC<IOrderDiscussionProps> = ({ order, creatorsName }) => {
  const dispatch = useDispatch();

  const { commentList } = useSelector((state: AppStateType) => state.orders);

  const [isOpenDiscussion, setIsOpenDiscussion] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    !!order?.id && dispatch(fetchCommentList(order.id));
  }, [order?.id]);

  const toggleIsOpenDiscussion = (): void => {
    setMessage("");
    setIsOpenDiscussion((isPrev) => !isPrev);
  };

  const onAddMessage = async (): Promise<void> => {
    await OrdersAPI.setComment(order.id, message.trim(), creatorsName)
      .then(() => {
        setMessage("");
        dispatch(fetchCommentList(order.id));
      })
  }

  return (
    <div className="mt-8 tablet:mt-15">
      <div className="mb-3">Обсуждение</div>
      <div className={css.commentsBlock}>
        {!!commentList?.length && commentList.map((comment: OrderCommentType) => (
          <Comment key={comment.id} commentItem={comment} />
        ))}
      </div>
      {isOpenDiscussion ? (
        <div className={`flex justify-between pt-4 overflow-hidden ${css.discussionForm}`}>
          <Textarea
            theme="white"
            textSize="small"
            placeholder="Ваше сообщение"
            value={message}
            onChange={(value: string) => setMessage(value)}
            className="mb-6 mr-3 w-full"
          />
          <div>
            <Button
              theme="filled"
              onClick={onAddMessage}
              className={`mb-2 tablet:self-end ${css.discussionBtn}`}
              disabled={!message?.trim()}
            >
              Отправить
            </Button>
            <Button
              theme="primary"
              onClick={toggleIsOpenDiscussion}
              className={`tablet:self-end ${css.discussionBtn}`}
            >
              Отменить
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center mt-5 mobile:mt-10 ${css.squarePlusButton}`}
          onClick={toggleIsOpenDiscussion}
        >
          <SquareWithPlusIcon />
          <span className="cursor-pointer ml-2 text-n1 tablet:ml-1 uppercase">
            добавить комментарий
          </span>
        </div>
      )}
    </div>
  );
};

export default OrderDiscussion;
