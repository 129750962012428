import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_PRODUCTS_CATALOG_REQUEST,
  GET_PRODUCTS_CATALOG_SUCCESS,
  GET_PRODUCTS_CATALOG_FAILURE,
  GET_PRODUCT_STATUS_SUCCESS,
  GET_PRODUCT_STATUS_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import * as ProductsAPI from "@/api/products.api";
import {
  ProductItemType,
  ProductListType,
  ProductType,
  IRequestSupplierProductsCatalogFilters,
  IProductsVerificationCatalogFilters,
} from "app/types";

export type ProductsActionsTypes =
  | GetProductsRequestType
  | GetProductsSuccessType
  | GetProductsFailureType
  | GetProductSuccessType
  | GetProductFailureType
  | UpdateProductSuccessType
  | UpdateProductFailureType
  | DeleteProductSuccessType
  | DeleteProductFailureType
  | GetProductsCatalogRequestType
  | GetProductsCatalogSuccessType
  | GetProductsCatalogFailureType
  | GetProductStatusSuccessType
  | GetProductStatusFailureType;

type GetProductsRequestType = {
  type: typeof GET_PRODUCTS_REQUEST;
};

export const getProductsRequest = (): GetProductsRequestType => ({
  type: GET_PRODUCTS_REQUEST
});

type GetProductsSuccessType = {
  type: typeof GET_PRODUCTS_SUCCESS;
  payload: {
    productsList: ProductListType;
  };
};

export const getProductsSuccess = (
  productsList: ProductListType
): GetProductsSuccessType => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: {
    productsList: productsList,
  },
});

type GetProductsFailureType = {
  type: typeof GET_PRODUCTS_FAILURE;
  payload: {
    error: string;
  };
};

export const getProductsFailure = (error?: string): GetProductsFailureType => ({
  type: GET_PRODUCTS_FAILURE,
  payload: {
    error,
  },
});

export const getProductsList = (filters: IProductsVerificationCatalogFilters)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsTypes
> => async (dispatch) => {
  dispatch(getProductsRequest());

  try {
    const response = await ProductsAPI.getProducts(filters);

    dispatch(getProductsSuccess(response.data));
  } catch (err) {
    dispatch(getProductsFailure(err.message));
  }
};

type GetProductsCatalogRequestType = {
  type: typeof GET_PRODUCTS_CATALOG_REQUEST;
};

export const getProductsCatalogRequest = (): GetProductsCatalogRequestType => ({
  type: GET_PRODUCTS_CATALOG_REQUEST
});

type GetProductsCatalogSuccessType = {
  type: typeof GET_PRODUCTS_CATALOG_SUCCESS;
  payload: {
    productsCatalogList: ProductListType;
  };
};

export const getProductsCatalogSuccess = (
  productsCatalogList: ProductListType
): GetProductsCatalogSuccessType => ({
  type: GET_PRODUCTS_CATALOG_SUCCESS,
  payload: {
    productsCatalogList: productsCatalogList,
  },
});

type GetProductsCatalogFailureType = {
  type: typeof GET_PRODUCTS_CATALOG_FAILURE;
  payload: {
    error: string;
  };
};

export const getProductsCatalogFailure = (
  error?: string
  ): GetProductsCatalogFailureType => ({
  type: GET_PRODUCTS_CATALOG_FAILURE,
  payload: {
    error,
  },
});

export const getProductsCatalogList = (
  filters: IRequestSupplierProductsCatalogFilters
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsTypes
> => async (dispatch) => {
  dispatch(getProductsCatalogRequest());

  try {
    const response = await ProductsAPI.getProductsCatalog(filters);

    dispatch(getProductsCatalogSuccess(response.data));
  } catch (err) {
    dispatch(getProductsCatalogFailure(err.message));
  }
};

type GetProductStatusSuccessType = {
  type: typeof GET_PRODUCT_STATUS_SUCCESS;
  payload: {
    getProductStatus: number;
  };
};

export const getProductStatusSuccess = (
  getProductStatus: number
): GetProductStatusSuccessType => ({
  type: GET_PRODUCT_STATUS_SUCCESS,
  payload: {
    getProductStatus,
  },
});

type GetProductSuccessType = {
  type: typeof GET_PRODUCT_SUCCESS;
  payload: {
    product: ProductType;
  };
};

export const getProductSuccess = (
  product: ProductType
): GetProductSuccessType => ({
  type: GET_PRODUCT_SUCCESS,
  payload: {
    product,
  },
});

type GetProductStatusFailureType = {
  type: typeof GET_PRODUCT_STATUS_FAILURE;
  payload: {
    getProductStatus: number;
  };
};

export const getProductStatusFailure = (
  getProductStatus: number
): GetProductStatusFailureType => ({
  type: GET_PRODUCT_STATUS_FAILURE,
  payload: {
    getProductStatus,
  },
});

type GetProductFailureType = {
  type: typeof GET_PRODUCT_FAILURE;
  payload: {
    error: string;
  };
};

export const getProductFailure = (error?: string): GetProductFailureType => ({
  type: GET_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const getProductInfo = (id)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsTypes
> => async (dispatch) => {
  try {
    const response = await ProductsAPI.getProduct(id);

    dispatch(getProductSuccess(response?.data));
    dispatch(getProductStatusSuccess(response?.status));
  } catch (err) {
    dispatch(getProductFailure(err.message));
    dispatch(getProductStatusFailure(err.response.status));
  }
};

type UpdateProductSuccessType = {
  type: typeof UPDATE_PRODUCT_SUCCESS;
  payload: {
    updateProductStatus: number;
  };
};

export const updateProductSuccess = (
  updateProductStatus: number
): UpdateProductSuccessType => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: {
    updateProductStatus,
  },
});

type UpdateProductFailureType = {
  type: typeof UPDATE_PRODUCT_FAILURE;
  payload: {
    error: string;
  };
};

export const updateProductFailure = (error?: string): UpdateProductFailureType => ({
  type: UPDATE_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const updateProductInfo = (items?: ProductItemType[])
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsTypes
> => async (dispatch) => {
  try {
    const response = await ProductsAPI.updateProduct(items);

    dispatch(updateProductSuccess(response.status));
  } catch (err) {
    dispatch(updateProductFailure(err.response.data.detail));
  }
};

type DeleteProductSuccessType = {
  type: typeof DELETE_PRODUCT_SUCCESS;
  payload: {
    deleteProductStatus: number;
  };
};

export const deleteProductSuccess = (
  deleteProductStatus: number
): DeleteProductSuccessType => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: {
    deleteProductStatus,
  },
});

type DeleteProductFailureType = {
  type: typeof DELETE_PRODUCT_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteProductFailure = (error?: string): DeleteProductFailureType => ({
  type: DELETE_PRODUCT_FAILURE,
  payload: {
    error,
  },
});

export const deleteProductFromList = (id)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  ProductsActionsTypes
> => async (dispatch) => {
  try {
    const response = await ProductsAPI.deleteProduct(id);

    dispatch(deleteProductSuccess(response.status));
  } catch (err) {
    dispatch(deleteProductFailure(err.response.data.detail));
  }
};
