import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { getOrganizationFirstLetter } from "@/utils/getOrganizationFirstLetter";
import ImageContainer from "@/components/ui-kit/image";

import css from "./SupplierCardInfo.modules.scss";

interface ISupplierCardInfoProps {
}

const SupplierCardInfo: FC<ISupplierCardInfoProps> = ({}): JSX.Element => {
  const { supplier } = useSelector((state: AppStateType) => state.suppliers);

  const organizationFirstLetter: string = useMemo(() => getOrganizationFirstLetter(supplier?.name), [supplier?.name]);

  const renderContactItem = (title: string, item: string): JSX.Element => {
    return (
      <div>
        <span className={`text-n2 text-blue-grey mb-1 ${css.displayBlock}`}>
          {title}
        </span>
        {!!item ? (
          <p className={`whitespace-pre mb-2 ${css.displayBlock}`}>
            {item}
          </p>
        ) : (
          <p className="mb-2">Не указан</p>
        )}
      </div>
    );
  };

  const renderOrganizationFirstLetter = (): JSX.Element => {
    return (
      <div className={`absolute inset-0 ${css.supplierLogoStub} ${css.supplierLogo}`}>
        {organizationFirstLetter}
      </div>
    );
  };

  return (
    <div className="flex bg-white p-5">
      <div className={css.supplierLogoContainer}>
        <div className={`relative ${css.supplierLogoBlock}`}>
          {supplier?.logo ? (
            <ImageContainer
              src={supplier?.logo}
              alt="Логотип"
              image={renderOrganizationFirstLetter()}
              imageClass={`absolute inset-0 ${css.supplierLogo}`}
              noImageClass=""
            />
          ) : renderOrganizationFirstLetter()}
        </div>
      </div>

      <div className={css.supplierContacts}>
        <h4 className={css.contactsTitle}>Контакты</h4>
        <div className={`flex ${css.contacts}`}>
          <div className={`flex ${css.contactsBlock}`}>
            {renderContactItem("Телефон", supplier?.phone)}
            {renderContactItem("Email", supplier?.email)}
          </div>

          <div className={`flex justify-between ${css.contactsBlock}`}>
            {renderContactItem("ИНН", supplier?.inn)}
            {renderContactItem("Юридический адрес", supplier?.jurAddress)}
          </div>

          <div className={`flex justify-between ${css.contactsBlock}`}>
            {renderContactItem("Сайт", supplier?.site)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierCardInfo;
