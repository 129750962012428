import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { getSupplierInfo } from "@/actions/suppliers.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import SupplierCardInfo
  from "@/components/pages/platform/pages/suppliers/SupplierCard/SupplierCardInfo/SupplierCardInfo";
import SupplierCardCatalog
  from "@/components/pages/platform/pages/suppliers/SupplierCard/SupplierCardCatalog/SupplierCardCatalog";

interface ISupplierCardProps extends RouteComponentProps {
  supplierId?: number;
}

const SupplierCard: FC<ISupplierCardProps> = ({ supplierId }): JSX.Element => {
  const dispatch = useDispatch();

  const { supplier } = useSelector((state: AppStateType) => state.suppliers);

  useEffect(() => {
    if (supplierId) {
      dispatch(getSupplierInfo(supplierId));
    }
  },[supplierId]);

  return (
    <div>
      <h1 className="text-x1 mb-2 tablet:mb-5">{supplier?.name}</h1>
      <SupplierCardInfo />
      <div id="catalog">
        <SupplierCardCatalog supplierId={supplierId} />
      </div>
    </div>
  );
};

export default SupplierCard;
