import axios from "@/utils/axios";
import {
  IRequestOrderListFilters,
  UpdateOrderEntityParamsType,
  UploadFileType
} from "app/types";
import { FileTypesValue } from "@/components/pages/platform/pages/DocumentManagement/UploadFilesModal/UploadFilesModal";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

export const createOrder = async (
  deliveryType: string,
  deliveryAddress: string,
  paymentType: string,
  deliveryDate: string,
  comment: string,
  commercialOfferType: string,
) => {
  return axios.POST(API_BASE, "/api/orders/v1/order", {
    deliveryType,
    deliveryAddress,
    paymentType,
    deliveryDate,
    comment,
    commercialOfferType
  });
};

// листинг заказов
export const getOrders = async (
  filters: IRequestOrderListFilters
) => {
  return axios.GET(API_BASE, "/api/orders/v1/orders", filters);
};

export const getOrder = async (orderId: number) => {
  return axios.GET(API_BASE, `/api/orders/v1/order/${orderId}`);
};

export const getOrderAddresses = async (page?: number, pageSize?: number) => {
  return axios.GET(API_BASE, "/api/orders/v1/orders/addresses", {
    page,
    pageSize,
  });
};

export const updateOrderEntity = async (
  params: UpdateOrderEntityParamsType
) => {
  return axios.PATCH(API_BASE, "/api/orders/v1/order", params);
};

export const editOrder = async (
  orderId: number,
  delivery_type: string,
  delivery_address: string,
  payment_type: string,
  delivery_date: string,
  comment: string,
) => {
  return axios.PUT(API_BASE, `/api/orders/v1/order/${orderId}`, {
    delivery_type: delivery_type,
    delivery_address: delivery_address,
    payment_type: payment_type,
    delivery_date: delivery_date,
    comment: comment,
  });
};

export const editOrderQuantity = async (
  orderId: number,
  positionId: number,
  quantity: number,
) => {
  return axios.PUT(API_BASE, `/api/orders/v1/order/${orderId}/position/${positionId}`, {
    quantity: quantity,
  });
};

export const deleteOrderQuantity = async (
  orderId: number,
  positionId: number,
  quantity?: number,
) => {
  return axios.DELETE(API_BASE, `/api/orders/v1/order/${orderId}/position/${positionId}`, {
    quantity: quantity,
  });
};

// используется при согласовании / отклонении заказа
export const approveOrderStatus = async (orderId: number, approved: boolean) => {
  return axios.POST(API_BASE, `/api/orders/v1/order/${orderId}/approve`, { approved });
};

// Перевод статуса заказа. Доступен перевод только поставщику из статуса "Новый" в статус "В обработке".
// Руками статус не передаем, бэк сам проверяет
export const updateOrderStatus = async (orderId: number) => {
  return axios.POST(API_BASE, `/api/orders/v1/order/${orderId}/status`);
};

export const sendToRevision = async (orderId: number, comment: string, creatorsName: string) => {
  return axios.POST(API_BASE, `/api/orders/v1/order/${orderId}/send_to_revision`, {
    comment,
    creatorsName
  });
};

export const saveOrderServiceSettings = async (
  sendOrdersToTpPeriod: number,
  updateOrdersFromTpPeriod: number,
  sendOrderStatusToTpPeriod: number,
  autocancelOrdersPeriod: number,
  notifyOrderExpiredAcceptanceDays: number,
) => {
  return axios.POST(API_BASE, "/api/orders/v1/settings", {
    sendOrdersToTpPeriod: sendOrdersToTpPeriod,
    updateOrdersFromTpPeriod: updateOrdersFromTpPeriod,
    sendOrderStatusToTpPeriod: sendOrderStatusToTpPeriod,
    autocancelOrdersPeriod: autocancelOrdersPeriod,
    notifyOrderExpiredAcceptanceDays: notifyOrderExpiredAcceptanceDays,
  });
};

export const getOrderServiceSettings = async () => {
  return axios.GET(API_BASE, "/api/orders/v1/settings");
};

// Документы
export const createOrderDocument = async (file: File | UploadFileType, orderId: number, fileType: FileTypesValue) => {
  return axios.POST_FOR_IMPORT(API_BASE, `api/orders/v1/document?orderId=${orderId}&fileType=${fileType}`, {
    file,
  });
};

export const getDocument = async (orderId: number) => {
  return axios.GET(API_BASE, `/api/orders/v1/document/${orderId}`);
};

export const getOrderDocuments = async (orderId: number) => {
  return axios.GET(API_BASE, `/api/orders/v1/order/${orderId}/documents`);
};

export const deleteOrderDocument = async (orderId: number, documentId: number) => {
  return axios.DELETE(API_BASE, `/api/orders/v1/order/${orderId}/documents/${documentId}`);
};

// Добавление нового комментария к заказу
export const setComment = async (orderId: number, comment: string, creatorsName: string) => {
  return axios.POST(API_BASE, `/api/orders/v1/order/${orderId}/comments`,
    { comment, creators_name: creatorsName }
  );
};

// Получение списка комментариев к заказу
export const getCommentList = async (orderId: number) => {
  return axios.GET(API_BASE, `/api/orders/v1/order/${orderId}/comments`);
};

// Скачивание файла с выбранным отчетом за период
export const getReports = async (
  reportType: "orders" | "orders_items",
  dateFrom: string,
  dateTo: string,
) => {
  return axios.GET_FILE(
    API_BASE,
    "/api/orders/v1/orders/reports",
    { reportType, dateFrom, dateTo }
  );
};
