import React, { FC } from "react";

import cn from "classnames/bind";

import css from "./logoIcon.modules.scss";
import ImageContainer from "@/components/ui-kit/image";

const cx = cn.bind(css);

interface StarredIconProps {
  Icon: string;
  className?: string;
  organizationFirstLetter?: string;
}

const LogoIcon: FC<StarredIconProps> = ({
  Icon,
  className,
  organizationFirstLetter,
}) => {
  const renderOrganizationFirstLetter = (): JSX.Element => {
    return (
      <div className={cx("absolute inset-0", "starredIcon__defaltIcon", "starredIcon__main")}>
        {organizationFirstLetter}
      </div>
    );
  };

  return (
    <div className={cx("relative", "starredIcon", className)}>
      {Icon
        ? <ImageContainer
          src={Icon}
          alt="Логотип"
          image={renderOrganizationFirstLetter()}
          imageClass={cx("absolute inset-0", "starredIcon__main")}
          noImageClass=""
        />
        : <div className={cx("absolute inset-0", "starredIcon__main", "starredIcon__defaltIcon")}>
          {organizationFirstLetter}
        </div>
      }
    </div>
  );
}

export default LogoIcon;
