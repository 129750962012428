import React, {
  FC,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector, } from "react-redux";
import {
  ProductCategoryType, ProductImageType,
  ProductPropertyType,
  SupplierType,
} from "app/types";
import Button from "@/components/ui-kit/button";
import Counter from "@/components/ui-kit/counter";
import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";
import css from "../../../Catalog.modules.scss";
import { Link } from "gatsby";
import {
  addProduct,
  removeProduct,
  updateProduct,
  addProductInCartFailure,
  updateProductInCartFailure,
  removeProductFromCartFailure,
} from "@/actions/cart.actions";
import { AppStateType } from "@/reducers";
import { formatAmount } from "@/utils/formatPrice";
import { roleResolver } from "@/utils/roleResolver";
import { Tooltip } from "antd";
import AdaptableBlock from "@/components/ui-kit/adaptableBlock";

interface ICatalogListItemProps {
  id: number;
  name: string;
  rest: number;
  price: number;
  currency: string;
  status?: string;
  images: ProductImageType[];
  article: string;
  category: ProductCategoryType;
  supplier: SupplierType;
  properties: ProductPropertyType[];
  aosDuration?: string;
  measureUnitName?: string;
}

export const CatalogListItem: FC<ICatalogListItemProps> = ({
  id,
  name,
  rest,
  price,
  images,
  article,
  category,
  supplier,
  properties,
  aosDuration,
  currency,
}) => {
  const [isCounterActive, setIsCounterActive] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(1);

  const dispatch = useDispatch();

  const { userCart } = useSelector((state: AppStateType) => state.cart);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isAuditor, isConcordant, isController } = roleResolver(userInfo?.roles);

  const productInCart = userCart?.items?.find(item => item?.product?.id === id);
  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

  const canPutBasket: boolean = !isSupplier && !isAuditor && !isConcordant && !isController;

  useEffect(() => {
    setIsCounterActive(!!productInCart)
  }, [productInCart]);

  useEffect(() => {
    if (!!productInCart?.quantity && !isCounterActive) {
      setItemCount(productInCart?.quantity);
    }
  }, [productInCart?.quantity]);

  const renderNoPicture = useMemo(
    () => (
      <div className={`flex items-center flex-col ${css.productNoImage}`}>
        <Link to={`products/${id}`}><NoPictureIcon /></Link>
        <div className={`mt-4 ${css.catalogNoImageText}`}>Нет изображения</div>
      </div>
    ), []);

  const renderProperties = (properties: ProductPropertyType[]): JSX.Element => (
    <div className="flex gap-1">
      <div className="prop-left">
        {properties?.map(({ name }, index) => (
          <p key={`prop-name-${index}`} className="text-blue-grey">
            {name.charAt(0).toUpperCase()}{name.slice(1)}
          </p>
        ))}
      </div>
      <div className="prop-right">
        {properties?.map(({ value }, index) => (
          <p key={`prop-value-${index}`} className="text-black">
            {value}
          </p>
        ))}
      </div>
    </div>
  );

  const deleteBasket = (productId: number) => {
    dispatch(removeProduct(productId));
    dispatch(removeProductFromCartFailure());
  };

  const putBasket = (productId: number, quantity: number) => {
    dispatch(updateProduct(productId, quantity));
    dispatch(updateProductInCartFailure());
  };

  const activateCounter = (productId: number, quantity: number) => {
    return () => {
      setIsCounterActive(true);
      dispatch(addProduct(productId, quantity));
      dispatch(addProductInCartFailure());
    }
  };

  const renderCategory = category?.categoryName?.length > 120 ? (
    <Tooltip title={`${category?.categoryCode} - ${category?.categoryName}`}>
      {category?.categoryCode} - {category?.categoryName?.substring(0, 120).trim()}
      {category?.categoryName?.length > 120 && (
        <span>...</span>
      )}
    </Tooltip>
  ) : (<span>{category?.categoryCode} - {category?.categoryName}</span>);

  return (
    <>
      <div>
        <div
          key={id}
          className="mb-1 px-9 py-3 w-full flex justify-between bg-white"
          data-aos="fade-up"
          data-aos-duration={aosDuration}
          data-aos-once="true"
          data-aos-offset="0"
        >
          <div className="flex">
            <div className="mr-10">
              {!!images?.length
                ? (
                  <div key={images[0].id} className={css.productImage}>
                    <Link to={`products/${id}`}>
                      <img src={`${API_BASE}${images[0].link}`} />
                    </Link>
                  </div>
                ) : renderNoPicture}
            </div>
            <div className="product-label">
              <AdaptableBlock
                itemName={supplier?.name}
                stringLength={75}
                containerClassName="flex items-center text-n2 text-blue-grey mb-3"
              />
              <Link to={`products/${id}`} className="block mb-3 text-s1 text-blue-grey">
                {name?.length > 80 ? (
                  <Tooltip title={name}>
                    {name?.substring(0, 80).trim()}
                    {name?.length > 80 && (
                      <span>...</span>
                    )}
                  </Tooltip>
                ) : (
                  <span>{name}</span>
                )}
                <br />{article}
              </Link>
              <div className="w-1/2 mb-5 flex items-center text-n1">
                {!!category
                  ? renderCategory
                  : null
                }
              </div>
              <div className="mb-6 text-n2">
                {renderProperties(properties)}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className={`mb-2 ${price ? "text-x3" : "text-n2 text-blue-grey"}`}>
              {price ? formatAmount(price.toString(), currency) : "Цена по запросу"}
            </span>
            {canPutBasket && (
              <>
                {isCounterActive ? (
                  <Counter
                    min={0}
                    max={rest}
                    className={css.catalogCounter}
                    value={+itemCount}
                    setValue={setItemCount}
                    productId={productInCart?.id}
                    isCounterActive={isCounterActive}
                    setIsCounterActive={setIsCounterActive}
                    deleteBasket={deleteBasket}
                    putBasket={putBasket}
                  />
                ) : (
                  <Button
                    theme="primary"
                    className={css.catalogButton}
                    onClick={activateCounter(id, itemCount)}
                  >
                    <p className="text-n1">в корзину</p>
                  </Button>
                )}
              </>
            )}
            <a href={`mailto:${supplier?.email}?subject=Вопрос по каталогу товаров`} className="mt-3 border-none">
              <Button
                theme="primary"
                className={css.catalogButton}
              >
                <p className="text-n1">Связаться с поставщиком</p>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
