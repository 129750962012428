import React, { FC, useState, Dispatch, useEffect } from "react";
import { approveOrderStatusInfo, updateOrder } from "@/actions/orders.actions";
import { OrderItemsType, OrderType } from "app/types";
import { AdditionalProductType, ApprovalFactsResults } from "@/components/pages/platform/pages/CardOrder";
import { roleResolver } from "@/utils/roleResolver";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

import Button, { ButtonThemeType } from "@/components/ui-kit/button";
import ModalToRevision from "@/components/pages/platform/pages/CardOrder/CardOrderControls/ModalToRevision";
import { Modal } from "antd";

enum ApprovalRole { CONCORDANT = 1, AUDITOR = 2 }

interface ICardOrderControlsProps {
  order: OrderType;
  orderId: number;
  isEditMode: boolean;
  setIsEditMode: Dispatch<React.SetStateAction<boolean>>;
  currentOrder: OrderType;
  setCurrentOrder: Dispatch<React.SetStateAction<OrderType>>;
  additionalProducts: AdditionalProductType[];
  setAdditionalProducts: Dispatch<React.SetStateAction<AdditionalProductType[]>>;
  updateStatus: () => void;
  creatorsName: string;
  uploadedDocumentsCount: number;
}

const CardOrderControls: FC<ICardOrderControlsProps> = ({
  order,
  orderId,
  isEditMode,
  setIsEditMode,
  currentOrder,
  setCurrentOrder,
  additionalProducts,
  setAdditionalProducts,
  updateStatus,
  creatorsName,
  uploadedDocumentsCount,
}): JSX.Element => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const [isShowToRevisionModal, setIsShowToRevisionModal] = useState<boolean>(false);

  const {
    isSupplier,
    isPurchaser,
    isAuditor,
    isConcordant,
    isController
  } = roleResolver(userInfo?.roles);

  useEffect(() => {
    setIsShowToRevisionModal(false);
  }, [order?.status]);

  const isDisabledSubmitBtn: boolean = currentOrder?.items?.length === 0
    || additionalProducts?.some(({ productId }) => !productId);

  const isShowEditOrderBtn: boolean = order?.status === "processing";

  const cannotApproveOrder = (approvalRole: ApprovalRole.CONCORDANT | ApprovalRole.AUDITOR): boolean => currentOrder?.approvalFacts
    ?.find(({role}) => role === approvalRole)
    ?.result !== ApprovalFactsResults.REQUIRED;

  const isDisabledApproveBtn: boolean = (isAuditor && cannotApproveOrder(ApprovalRole.AUDITOR))
    || (isConcordant && cannotApproveOrder(ApprovalRole.CONCORDANT))
    || isEditMode;

  const isShowCancelBtn: boolean = order?.status !== "cancelled" && order?.status !== "closed" && !isController;

  const isShowToRevisionBtn: boolean = (isPurchaser || isAuditor || isConcordant)
    && (order?.status === "updated" || order?.status === "approval");

  // согласовать может поставщик в статусе "В обработке";
  // заказчик в статусе "Согласование изменений";
  // аудитор или согласовант в статусе "На согласовании КП"
  const isShowApproveBtn: boolean = (isSupplier && isShowEditOrderBtn)
    || isPurchaser && currentOrder?.status === "updated"
    || ((isAuditor || isConcordant) && currentOrder?.status === "approval");

  const editOrder = async (): Promise<void> => {
    const productItems: AdditionalProductType[] = currentOrder?.items?.map((item: OrderItemsType) => {
      return ({ productId: item.product.id, count: item.quantity, itemPrice: item.product.price })
    });

    const additionalItems: AdditionalProductType[] = additionalProducts
      ?.filter((p: AdditionalProductType) => !!p.productId && !!p.count && !!p.itemPrice)
      ?.map(({ productId, count, itemPrice }) => {
        return ({ productId, count, itemPrice })
      });

    await dispatch(updateOrder({
        id: orderId,
        deliveryDate: currentOrder.deliveryDate,
        items: [...productItems, ...additionalItems]
      })
    );

    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const openCancelModal = (): void => {
    Modal.confirm({
      cancelText: "Вернуться назад",
      okText: "Отменить заказ",
      title: "Вы уверены, что хотите отменить заказ?",
      onOk() {
        dispatch(approveOrderStatusInfo(orderId, false));
      },
    });
  };

  const cancelChanges = (): void => {
    setCurrentOrder(order);
    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const openToRevisionModal = (): void => setIsShowToRevisionModal(true);

  const closeToRevisionModal = (): void => setIsShowToRevisionModal(false);

  const renderButton = (
    title: string,
    action: () => void,
    isDisabled: boolean = false,
    theme: ButtonThemeType = "filled",
  ): JSX.Element => {
    return (
      <Button
        theme={theme}
        className="mr-2"
        onClick={action}
        disabled={isDisabled}
      >
        {title}
      </Button>
    );
  };

  const editControls: JSX.Element = isSupplier && isShowEditOrderBtn && (
    isEditMode ? (
      <div className="flex">
        {renderButton("Отмена", cancelChanges)}
        {renderButton("Подтвердить", editOrder, isDisabledSubmitBtn)}
      </div>
    ) : (
      renderButton("Редактировать заказ", () => setIsEditMode(true))
  ))



  const controls: { isShow: boolean, button: JSX.Element }[] = [
    {
      isShow: isShowApproveBtn,
      button: renderButton("Согласовать", () => dispatch(approveOrderStatusInfo(orderId, true)), isDisabledApproveBtn),
    },
    {
      isShow: isSupplier && currentOrder?.status === "preparation",
      button: renderButton("Загрузить КП", updateStatus, !uploadedDocumentsCount),
    },
    {
      isShow: isSupplier && currentOrder?.status === "new",
      button: renderButton("Взять в обработку", updateStatus),
    },
    {
      isShow: isShowToRevisionBtn,
      button: renderButton("Отправить на доработку", openToRevisionModal, false, "greyOutline"),
    },
    {
      isShow: isShowCancelBtn,
      button: renderButton("Отменить заказ", openCancelModal, isEditMode, "greyOutline"),
    }
  ];

  return (
    <div className={`flex ${isSupplier && isShowEditOrderBtn ? "justify-between" : "justify-end"} mt-2`}>
      {editControls}
      <div className="flex">
        {controls.map(({isShow, button}) => isShow && button)}
      </div>
      {isShowToRevisionModal && (
        <ModalToRevision
          orderId={orderId}
          isOpen={isShowToRevisionModal}
          onClose={closeToRevisionModal}
          creatorsName={creatorsName}
        />
      )}
    </div>
  );
};

export default CardOrderControls;
