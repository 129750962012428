import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCommentList, sendOrderToRevision } from "@/actions/orders.actions";

import { Modal, Input } from "antd";
import Button from "@/components/ui-kit/button";

import css from "./style.modules.scss";

interface IModalToRevisionProps {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
  creatorsName: string;
}

const ModalToRevision: FC<IModalToRevisionProps> = ({
  orderId,
  isOpen,
  onClose,
  creatorsName,
}): JSX.Element => {
  const dispatch = useDispatch();

  const { TextArea } = Input;

  const [comment, setComment] = useState<string>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => setComment(e.target.value);

  const sendComment = async (): Promise<void> => {
    await dispatch(sendOrderToRevision(orderId, comment, creatorsName));
    dispatch(fetchCommentList(orderId));
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      closable
      footer={null}
    >
      <div>
        <p className={css.title}>Отправить заказ на доработку</p>
        <p className={css.descr}>Пожалуйста, опишите ваши замечания к заказу и подтвердите действие</p>
        <TextArea
          placeholder="Комментарий"
          rows={4}
          maxLength={500}
          size="large"
          showCount
          onChange={handleChange}
        />
        <div className={`flex items-center justify-end gap-2 ${css.controls}`}>
          <Button theme="greyOutline" onClick={onClose}>Отменить</Button>
          <Button
            theme="black"
            disabled={!comment}
            onClick={sendComment}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalToRevision;
