export const getOrganizationFirstLetter = (organization: string): string => {
  const strWithoutSpecialSymbols: string = organization
    && organization.replace(/['"/\/|\^@!#$%&*()\-\+?{}[\]\\.,;:\<\>\=`~_№]/g, "");

  const strWithoutLLC: string = strWithoutSpecialSymbols
    && strWithoutSpecialSymbols.replace(/ООО|ИП|АО|ПАО|ОАО|АНО|OOO/, ""
  );

  return strWithoutLLC && strWithoutLLC.trim()[0];
};
