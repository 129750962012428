import React, { FC, useState } from 'react';
import NoPictureIcon from '@/assets/icons/no-picture.inline.svg';

interface ImageContainerProps {
  src: string;
  alt: string;
  imageClass?: string;
  noImageClass?: string;
  image?: JSX.Element;
}

const ImageContainer: FC<ImageContainerProps> = ({
  src,
  alt,
  image,
  imageClass,
  noImageClass
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const handleError = (): void => {
    setHasError(true);
  };

  const isImage = (url: string = ""): boolean => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  const renderNoPicture = (): JSX.Element => {
    return (
      image ?? (
        <NoPictureIcon width="100%" height="100%" className={noImageClass ? noImageClass : ""} />
      )
    );
  };

  return isImage(src) && !hasError
    ? <img src={src} alt={alt} onError={handleError} className={imageClass ? imageClass : ""} />
    : renderNoPicture();
};

export default ImageContainer;
